import "./montimin.css";
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import VideoPlayer from './VideoPlayer';
import { Helmet } from "react-helmet";

function montimin() {
    return(
        <>
                <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
    <div class="container1">
    <Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/Montime%20te%20klimave" />
            </Helmet>
    <h1>Montimet që i kemi bërë ne</h1>
    <div class="images1">
      <img src="images/mbrenda1.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda2.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda3.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda7.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda5.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/perjasht1.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/perjasht2.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/perjasht3.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/perjasht4.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/perjasht5.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda13.jpg" className="servis555" alt="Image 1"></img>
      <BackToTopButton />
      <img src="images/mbrenda9.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda10.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda11.jpg" className="servis555" alt="Image 1"></img>
      <img src="images/mbrenda12.jpg" className="servis555" alt="Image 1"></img>


    </div>
  </div>
  <BackToTopButton />
  <footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>

  

</>
    )
}
export default  montimin;
