import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './conponents/Layout';
import NoPage from './conponents/NoPage';
import Home from './conponents/Home';
import Contact from './conponents/Contact';
import Aboutus from './conponents/Aboutus';
import Gallery from './conponents/Gallery';
import Istallacija from './conponents/Istallacija';
import Frigoriferat from './conponents/Frigoriferat';
import  Servisimim from './conponents/servis';
import  Montimim from './conponents/Montimim';
import Market from './conponents/Market';
import { HelmetProvider } from 'react-helmet-async';



function App() {
  return(
    <BrowserRouter>
    <HelmetProvider>
    <Routes>
      <Route path='/' element={<Layout/>} >
        <Route index element={<Home/>}/>
        <Route path='gallery' element={<Gallery/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='frigoriferat' element={<Frigoriferat/>}/>
        <Route path='market' element={<Market/>}/>
        <Route path='istalacija' element={<Istallacija/>}/>
        <Route path='Servisimim' element={<Servisimim/>}/>
        <Route path='Montime te klimave' element={<Montimim/>}/>
        <Route path='*' element={<NoPage/>}/>
      </Route>
    </Routes>
    </HelmetProvider>

    </BrowserRouter>
  )
}

export default App;

