import React from 'react';
import './Gallery.css';
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import { Helmet } from 'react-helmet';


function Gallery({ photos }) {

  return (
    <>
    <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
    <head>
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>
<Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/gallery" />
            </Helmet>
  <h1 className='mbrenda'>Montimi i klimave</h1>
    <div class="gallery-container">
      <img src="images/mbrenda1.jpg" alt="Image 1"></img>
      <img src="images/mbrenda2.jpg" alt="Image 2"></img>
      <img src="images/mbrenda3.jpg" alt="Image 3"></img>
      <img src="images/mbrenda5.jpg" alt="Image 4"></img>
      <img src="images/mbrenda12.jpg" alt="Image 5"></img>
      <img src="images/mbrenda13.jpg" alt="Image 6"></img>
      <img src="images/mbrenda9.jpg" alt="Image 7"></img>
      <img src="images/mbrenda10.jpg" alt="Image 8"></img>
      <img src="images/mbrenda11.jpg" alt="Image 9"></img>
      <img src="images/mbrenda7.jpg" alt="Image 9"></img>
    </div>
    <h1 className='mbrenda'>Montimi i Motorave </h1>
    <div class="gallery-container1">
      <img src="images/perjasht1.jpg" alt="Image 1"></img>
      <img src="images/perjasht2.jpg" alt="Image 2"></img>
      <img src="images/perjasht3.jpg" alt="Image 3"></img>
      <img src="images/perjasht4.jpg" alt="Image 4"></img>
      <img src="images/perjasht5.jpg" alt="Image 5"></img>
      <img src="images/perjasht6.jpg" alt="Image 6"></img>
      <img src="images/perjasht7.jpg" alt="Image 7"></img>
      <img src="images/perjasht8.jpg" alt="Image 8"></img>
      <img src="images/perjasht9.jpg" alt="Image 9"></img>
      <img src="images/perjasht10.jpg" alt="Image 9"></img>
    </div>
    <h1 className='mbrenda'>Montimi i izollacijave </h1>
    <div class="gallery-container1">
      <img src="images/izollacija1.jpg" alt="Image 1"></img>
      <img src="images/izollacija2.jpg" alt="Image 2"></img>
      <img src="images/izollacija3.jpg" alt="Image 3"></img>
      <img src="images/izollacija4.jpg" alt="Image 4"></img>
      <img src="images/izollacija5.jpg" alt="Image 5"></img>
      <img src="images/izollacija6.jpg" alt="Image 6"></img>
      <img src="images/izollacija7.jpg" alt="Image 7"></img>
      <img src="images/izollacija8.jpg" alt="Image 8"></img>
      <img src="images/izollacija9.jpg" alt="Image 9"></img>
      <img src="images/izollacija10.jpg" alt="Image 9"></img>
    </div>
    <BackToTopButton />
    
    <footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
</>
  );
}

export default Gallery;