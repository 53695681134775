
import React, { useEffect, useState } from 'react';
import './Market.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slideshow from './slideshow';


function Card() {
    const [activeSection, setActiveSection] = useState(null);
    const Phone = () => {
    // Replace the phone number below with the number you want to call
    window.location.href = 'tel:070 305 210';
  };
  const resetActiveSection = () => {
    setActiveSection(null);
  };

  const images = [
    'images/icona/slideshow1.jpg',
    'images/icona/slideshow2.jpg',
    'images/icona/slideshow3.jpg'
  ];
    return (
        <>
            <div className='buttons-container'>
          <button onClick={() => setActiveSection('Vivax')}>Vivax</button>
          <button onClick={() => setActiveSection('Hisense')}>Hisense</button>
          <button onClick={resetActiveSection}>Show All</button>
        </div>
            <div className='div-container'>

<section id="card1" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeqi.png' className='reklama1'></img>
                    </div>
                    <div className="card__title">Vivax ACP-12CH35AEQIS R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:19.900 Mkd <br></br>Cmimi me Montim:25.000 Mkd</div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2>Vivax  ACP-12CH35AEQIS R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (6.1/4.0)</p>
    <p className='paragrafi1'>4162.00 W</p>
    <p className='paragrafi1'>4220.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>1213 W</p>
    <p className='paragrafi1'>1088 W</p>
    <p className='paragrafi1'>540.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>40.50 dB</p>
    <p className='paragrafi1'>NGA -15 ℃  DERI NË 50 ℃</p>
    <p className='paragrafi1'>7.60 kg</p>
    <p className='paragrafi1'>23.20 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>720 X 495 X 270 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeni.png' className='reklama1'></img>
                    </div>
                    <div className="card__title">Vivax ACP-12CH35AENI R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: ОД -10℃ ДО 50℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:23.990  Mkd <br></br>Cmimi me Montim:28.000 Mkd</div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2>Vivax ACP-12CH35AENI R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7.0/4.2)</p>
    <p className='paragrafi1'>4308.00 W</p>
    <p className='paragrafi1'>4381.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>1096 W</p>
    <p className='paragrafi1'>1027 W</p>
    <p className='paragrafi1'>530.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>37.00 dB</p>
    <p className='paragrafi1'>NGA -20 ℃  DERI NË 50 ℃</p>
    <p className='paragrafi1'>8.70 kg</p>
    <p className='paragrafi1'>23.70 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>720 X 495 X 270 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>
<section id="card3" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeni.png' className='reklama1'></img>
                    </div>
                    <div className="card__title">Vivax </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: ОД -10℃ ДО 50℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:23.990  Mkd <br></br>Cmimi me Montim:28.000 Mkd</div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2>Vivax ACP-12CH35AENI R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7.0/4.2)</p>
    <p className='paragrafi1'>4308.00 W</p>
    <p className='paragrafi1'>4381.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>1096 W</p>
    <p className='paragrafi1'>1027 W</p>
    <p className='paragrafi1'>530.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>37.00 dB</p>
    <p className='paragrafi1'>NGA -20 ℃  DERI NË 50 ℃</p>
    <p className='paragrafi1'>8.70 kg</p>
    <p className='paragrafi1'>23.70 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>720 X 495 X 270 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>
</div>
    
<footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
   </>


  );
}

export default Card;