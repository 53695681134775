import { Link, Outlet} from 'react-router-dom';
import './Layout.css';

function Layout() {
  return (
    <>
    <head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Frigoservis</title>
  {/* <!-- other head elements --> */}
  <meta name="google-site-verification" content="Ne bëjm Montimin Shitjen dhe Servisime per klima" />
</head>
<div class="header">
  <img src="images/neworiginal5.png" alt="Logo" class="logonavbar" />
  <ul class="navbar">
    <li>
      <a href="/" class="home">Home</a>
    </li>
    <li>
      <Link to="/market" className='link'>Market</Link>
    </li>
    <li>
      <Link to="/gallery" className='link'>Gallery</Link>
    </li>
    <li>
      <Link to="/contact" className='link'>Kontakt</Link>  
    </li>
  </ul>
</div>
<div className='hide'>
<Link to="/istalacija" className='link'>Lackierung</Link>
<Link to="/frigoriferat" className='link'>Shiko Me shumë</Link>
<Link to="/Servisimim" className='link'>Shiko Me shumë</Link>
<Link to="/Montime te klimave" className='link'>Shiko Me shumë</Link>
</div>
    <Outlet />
    </>
  );
}

export default Layout;