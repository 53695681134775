import React from 'react';

const VideoPlayer = () => {
  return (
    <div style={{ maxWidth: '500px' , margin: '0 auto' }}>
      <video controls width="100%">
        <source src="images/pastrimi.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
