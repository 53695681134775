import './Home.css';
import React, { useState, useEffect } from 'react';
import logo from './images/background1.jpg';
import replacementLogo from './images/replacment.jpg';
import { Link } from 'react-router-dom';
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import {Helmet} from "react-helmet";

function Home (){
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []); // Empty dependency array to run only once on mount

    return(
        <>
        <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
<head>
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>
            <div className="full-screen-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/" />
            </Helmet>
            <a className="logo" href="">
        {isMobile ? (
          <img src={replacementLogo}  className="replacement-logo" alt="Logo" />
        ) : (
          <img src={logo} alt="Logo" />
        )}
      </a>
      <div className="text-overlay">
      <p className='problem'>Keni ndonje problem Me kondicionerin tuaj?</p>
        <p className='telefon'>Tel:(072-305-210)</p>  
        <p className='telefon'>Tel:(070-567-923)</p>          
        </div>
    </div>
    <div className="containeri">
      <div className="text-containeri">
        <p className='pt'>Kush Jemi Ne?</p>
        <p className='p'>Frigo servis me përvoj pune 25 vjecare në lëmin e
         sistemit te ftohjes si dhe montim servisim të kondicionerve në mënyrë 
         profesionale ne ju ofrojm cilsi kualitet  
dhe garancion për punën tonë.</p>
      </div>
    </div>
    <section className="services">
      <h2>Çfar Ofrojm Ne!</h2>
      <div className="services-container">
        <div className="service">
        <span class="material-symbols-outlined">air</span>          
        <h3>Montime të kondicionerve  </h3>
        <p>Ne bejmë montime të kondicionerve në menyrë profesionale në cdo vend dhe në cdo kend.</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Link to="Montime te klimave" className='link'>Shiko Me shumë</Link>
        </div>
        <div className="service">
        <span class="material-symbols-outlined">build</span>        
        <h3>Servisim të kondicionerve  </h3>
        <p>Ne bëjm edhe servisimin e cdo lloj condicioneri ne menyrë profesionale</p>
        <br></br>
        <br></br>       
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>


        <Link to="/Servisimim" className='link'>Shiko Me shumë</Link>
        </div>
        <div className="service">
        <span class="material-symbols-outlined">kitchen</span>        
        <h3>Servisim të frigoriferave</h3>
        <p>Ne bëjm servisim të frigoriferave si ndrim te motorave mbushje me gas (freon) të ndryshem</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Link to="/" className='link'>Shiko Me shumë</Link>
        </div>
        <div className="service">
        <span class="material-symbols-outlined">vital_signs</span>       
        <h3>Montim të istalacioneve </h3>
        <p>Ne bejm montimin e istalacionit për condicionerin prsh  (gypat elektrikèn kullimin e ujit në shtëpit tuaja) </p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>


        <Link to="/istalacija" className='link'>Shiko Me shumë</Link>
        </div>
      </div>
    </section>

    <BackToTopButton />
    <section className="services1">
      <h2>Çmimet?</h2>
      <div className="services-container1">
        <div className="service1">
        <h3>klima 3.5 kw </h3>
        <p><span class="material-symbols-outlined">euro</span><h2>100</h2></p>
        <div className='servicediv'>
        <p>3.0 meter Tubacionet e bakrit me izolim kablloja elektrike si dhe tubacioni fleksibil i ujit.</p>

        </div>
        </div>
        <div className="service1">
        <h3>klima 5.5 kw </h3>
        <p><span class="material-symbols-outlined">euro</span><h2>130</h2></p>
        <div className='servicediv'>
        <p>3.0 meter Tubacionet e bakrit me izolim kablloja elektrike si dhe tubacioni fleksibil i ujit.</p>
        </div>
        </div>
        <div className="service1">
        <h3>klima 7.5 kw </h3>
        <p><span class="material-symbols-outlined">euro</span><h2>160</h2></p>
        <div className='servicediv'>
        <p>3.0 meter Tubacionet e bakrit me izolim kablloja elektrike si dhe tubacioni fleksibil i ujit.</p>
        </div>
        </div>
      </div>
    </section>
    <div class="coinAnim">
      <h3>Klimat Që i Montojm Ne!</h3>
        <div class="coinAnim-inner">
          <span class="span1">
          <div class="coin"><img src="images/1.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/2.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/3.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/4.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/5.png"  width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/6.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/7.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/8.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/9.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/10.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/11.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/1.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/2.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/3.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/4.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/5.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/6.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/7.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/8.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/9.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/10.png" width="130px" height="145px"></img></div>
          <div class="coin"><img src="images/11.png" width="130px" height="145px"></img></div>


          </span>
        </div>
    </div>
    
<footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
        </>
    )
}
export default Home;