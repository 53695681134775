import React, { useEffect, useState } from 'react';
import './Market.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slideshow from './slideshow';
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import { Helmet } from 'react-helmet';


function Card() {
  const [activeSection, setActiveSection] = useState(null);
  const Phone = () => {
  // Replace the phone number below with the number you want to call
  window.location.href = 'tel:072 305 210';
};
const resetActiveSection = () => {
  setActiveSection(null);
};

const images = [
  'images/icona/slideshow1.jpg',
  'images/icona/slideshow2.jpg',
  'images/icona/slideshow3.jpg'
];
    return (
        <>

<head>
<link rel="preconnect" href="https://fonts.googleapis.com"></link>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>

</head>



<div>
<Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <link rel="canonical" href="/market" />
                <meta name="description" content='Klimat më të mira dhe më të lira ne Rajon'></meta>
            </Helmet>
      {/* <Slideshow images={images} /> */}
    </div>

    <div className='buttons-container'>
          <button onClick={() => setActiveSection('Vivax')} className='zgjidh'>Vivax</button>
          <button onClick={() => setActiveSection('Hisense')}className='zgjidh'>Hisense</button>
          <button onClick={() => setActiveSection('Favorit')}className='zgjidh'>Favorit</button>
          <button onClick={resetActiveSection}className='zgjidh' >Show All</button>
        </div>

            <div className='div-container'>
<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeqi.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-12CH35AEQIS R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:19.900  <br></br>Cmimi me Montim:25.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img>  ACP-12CH35AEQIS R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (6.1/4.0)</p>
    <p className='paragrafi1'>4162.00 W</p>
    <p className='paragrafi1'>4220.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>1213 W</p>
    <p className='paragrafi1'>1088 W</p>
    <p className='paragrafi1'>540.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>40.50 dB</p>
    <p className='paragrafi1'>NGA -15℃  DERI NË 50℃</p>
    <p className='paragrafi1'>7.60 kg</p>
    <p className='paragrafi1'>23.20 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>720 X 495 X 270 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/f122.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> F-12000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3400/3500 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:19.990  <br></br>Cmimi me Montim:23.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> F-12000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A++ (6.20/4.00)</p>
<p className='paragrafi1'>3800.00 W</p>
<p className='paragrafi1'>4000.00 W</p>
<p className='paragrafi1'>3400/3500 W</p>
<p className='paragrafi1'>1340 W</p>
<p className='paragrafi1'>950 W</p>
<p className='paragrafi1'>600.00 m3</p>
<p className='paragrafi1'>1.70 l/h</p>
<p className='paragrafi1'>54.00 dB</p>
<p className='paragrafi1'>NGA -15℃ DERI NË 49℃</p>
<p className='paragrafi1'>7.50 kg</p>
<p className='paragrafi1'>18.50 kg</p>
<p className='paragrafi1'>deri në 35 m2</p>
<p className='paragrafi1'>R32</p>
<p className='paragrafi1'>Filtri për pluhur</p>
<p className='paragrafi1'>650 X 455 X 278 mm</p>
<p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeni.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-12CH35AENI R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:23.990   <br></br>Cmimi me Montim:28.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-12CH35AENI R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7.0/4.2)</p>
    <p className='paragrafi1'>4308.00 W</p>
    <p className='paragrafi1'>4381.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>1096 W</p>
    <p className='paragrafi1'>1027 W</p>
    <p className='paragrafi1'>530.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>37.00 dB</p>
    <p className='paragrafi1'>NGA -20℃ DERI NË 50℃</p>
    <p className='paragrafi1'>8.70 kg</p>
    <p className='paragrafi1'>23.70 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>720 X 495 X 270 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/j12.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> J-12000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3500/3800 W<br></br>
                        Grofje / Ftofje: NGA -25 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:24.990  <br></br>Cmimi me Montim:27.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> J-12000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A++ (6.19/5.2)</p>
  <p className='paragrafi1'>4100.00 W</p>
  <p className='paragrafi1'>4200.00 W</p>
  <p className='paragrafi1'>3500/3800 W</p>
  <p className='paragrafi1'>1180 W</p>
  <p className='paragrafi1'>1100 W</p>
  <p className='paragrafi1'>600.00 m3</p>
  <p className='paragrafi1'>1.70 l/h</p>
  <p className='paragrafi1'>53.00 dB</p>
  <p className='paragrafi1'>NGA -25℃ DERI NË 49℃</p>
  <p className='paragrafi1'>8.50 kg</p>
  <p className='paragrafi1'>22.50 kg</p>
  <p className='paragrafi1'>deri në 35 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>705 X 530 X 280 mm</p>
  <p className='paragrafi1'>5 vjet</p>
</div>

</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>


<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/q12.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> Q-12000BTU</div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 3500/4200 W<br></br>
                        Grofje / Ftofje: NGA -35 ℃ DERI NË 53 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:28.500  <br></br>Cmimi me Montim:32.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> Q-12000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <div className='paragrafi1'>
  <p className='paragrafi1'>A+++ (8.5/4.6)</p>
  <p className='paragrafi1'>4100.00 W</p>
  <p className='paragrafi1'>4200.00 W</p>
  <p className='paragrafi1'>3500/4200 W</p>
  <p className='paragrafi1'>870 W</p>
  <p className='paragrafi1'>1060 W</p>
  <p className='paragrafi1'>650.00 m3</p>
  <p className='paragrafi1'>1.70 l/orë</p>
  <p className='paragrafi1'>57.00 dB</p>
  <p className='paragrafi1'>NGA -35℃ DERI NË 53℃</p>
  <p className='paragrafi1'>8.00 kg</p>
  <p className='paragrafi1'>24.50 kg</p>
  <p className='paragrafi1'>deri në 35 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>705 X 530 X 280 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>

</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>




<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/12aeri.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-12CH35AERI R32</div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: Nga -25 ℃ deri në 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:29.990  <br></br>Cmimi me Montim:32.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-12CH35AERI R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A+++ (8.5/4.6)</p>
    <p className='paragrafi1'>4308.00 W</p>
    <p className='paragrafi1'>4381.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>977 W</p>
    <p className='paragrafi1'>977 W</p>
    <p className='paragrafi1'>584.00 m3</p>
    <p className='paragrafi1'>1.20 l/orë</p>
    <p className='paragrafi1'>39.50 dB</p>
    <p className='paragrafi1'>Nga -25℃ deri në 50℃</p>
    <p className='paragrafi1'>8.60 kg</p>
    <p className='paragrafi1'>26.70 kg</p>
    <p className='paragrafi1'>deri në 35 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrë për pluhur</p>
    <p className='paragrafi1'>765 X 555 X 303 mm</p>
    <p className='paragrafi1'>5 vjetë</p>
  </div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>


<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aehi.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-12CH35AEHI+ R32</div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 3517/3810 W<br></br>
                        Grofje / Ftofje: NGA -25 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:32.990  <br></br>Cmimi me Montim:34.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-12CH35AEHI+ R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A+++ (8.5/4.6)</p>
    <p className='paragrafi1'>4308.00 W</p>
    <p className='paragrafi1'>4396.00 W</p>
    <p className='paragrafi1'>3517/3810 W</p>
    <p className='paragrafi1'>900 W</p>
    <p className='paragrafi1'>950 W</p>
    <p className='paragrafi1'>700.00 m3</p>
    <p className='paragrafi1'>1.20 l/h</p>
    <p className='paragrafi1'>40.00 dB</p>
    <p className='paragrafi1'>NGA -25 ℃ DERI NË 50℃</p>
    <p className='paragrafi1'>11.30 kg</p>
    <p className='paragrafi1'>26.40 kg</p>
    <p className='paragrafi1'>deri në 40 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>765 X 303 X 555 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>


<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/kb35.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img>  KB35YR3EG</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 3400/3800 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃ ДО 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:32.900 <br></br>Cmimi me Montim:35.000</div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri1'></img>  KB35YR3EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A++ (6.1/4.0)"</p>
    <p class='paragrafi1'>4000.00 W</p>
    <p class='paragrafi1'>4200.00 W</p>
    <p class='paragrafi1'>3400/3800 W</p>
    <p class='paragrafi1'>1140 W</p>
    <p class='paragrafi1'>1050 W</p>
    <p class='paragrafi1'>550.00 m3</p>
    <p class='paragrafi1'>1.20 l/h</p>
    <p class='paragrafi1'>39.00 dB</p>
    <p class='paragrafi1'>NGA -20℃ Deri 43℃</p>
    <p class='paragrafi1'>7.70 kg</p>
    <p class='paragrafi1'>22.00 kg</p>
    <p class='paragrafi1'>до 35 м2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'> Filtri për pluhur.</p>
    <p class='paragrafi1'>660 X 483 X 240 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>


</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>




<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/ka35.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img>  KA35XR0EG</div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 3500/3900 W<br></br>
                        Grofje / Ftofje: NGA -22 ℃ DERI NË 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:32.990  <br></br>Cmimi me Montim:34.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri1'></img>  KA35XR0EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A+++ (8.5/4.6)"</p>
    <p class='paragrafi1'>4000.00 W</p>
    <p class='paragrafi1'>4400.00 W</p>
    <p class='paragrafi1'>3500/3900 W</p>
    <p class='paragrafi1'>890 W</p>
    <p class='paragrafi1'>1000 W</p>
    <p class='paragrafi1'>580.00 m3</p>
    <p class='paragrafi1'>1.20 l/orë</p>
    <p class='paragrafi1'>40.00 dB</p>
    <p class='paragrafi1'>NGA -22 ℃DERI NË 43℃</p>
    <p class='paragrafi1'>7.50 kg</p>
    <p class='paragrafi1'>33.00 kg</p>
    <p class='paragrafi1'>deri në 40 m2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'>Filtri I pluhur</p>
    <p class='paragrafi1'>810 X 585 X 280 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>



</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>


<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/f122.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> F-18000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5100/5200 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:34.990  <br></br>Cmimi me Montim:36.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> F-18000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <div className='paragrafi1'>
  <p className='paragrafi1'>A++ (6.7/4.0)</p>
  <p className='paragrafi1'>5300.00 W</p>
  <p className='paragrafi1'>5300.00 W</p>
  <p className='paragrafi1'>5100/5200 W</p>
  <p className='paragrafi1'>1670 W</p>
  <p className='paragrafi1'>1400 W</p>
  <p className='paragrafi1'>850.00 m3</p>
  <p className='paragrafi1'>2.50 l/orë</p>
  <p className='paragrafi1'>57.00 dB</p>
  <p className='paragrafi1'>NGA -15℃ DERI NË 49℃</p>
  <p className='paragrafi1'>10.00 kg</p>
  <p className='paragrafi1'>23.00 kg</p>
  <p className='paragrafi1'>deri në 50 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>705 X 530 X 280 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>

</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>



<BackToTopButton />

































<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeqi1.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-18CH50AEQIS R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5275/5570 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:35.990  <br></br>Cmimi me Montim:40.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-18CH50AEQIS R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7.4/4.0)</p>
    <p className='paragrafi1'>5832.00 W</p>
    <p className='paragrafi1'>5850.00 W</p>
    <p className='paragrafi1'>5275/5570 W</p>
    <p className='paragrafi1'>1550 W</p>
    <p className='paragrafi1'>1570 W</p>
    <p className='paragrafi1'>840.00 m3</p>
    <p className='paragrafi1'>1.80 l/h</p>
    <p className='paragrafi1'>42.50 dB</p>
    <p className='paragrafi1'>NGA -15℃  DERI NË 50℃</p>
    <p className='paragrafi1'>10.00 kg</p>
    <p className='paragrafi1'>32.70 kg</p>
    <p className='paragrafi1'>deri në 55 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>805 X 554 X 330 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/qb35.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img> QG35XV0EG</div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 3500/4200 W<br></br>
                        Grofje / Ftofje: NGA -22 ℃ DERI NË 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:39.990  <br></br>Cmimi me Montim:43.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri1'></img> QG35XV0EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A+++ (8.1/5.1)"</p>
    <p class='paragrafi1'>4400.00 W</p>
    <p class='paragrafi1'>4800.00 W</p>
    <p class='paragrafi1'>3500/4200 W</p>
    <p class='paragrafi1'>790 W</p>
    <p class='paragrafi1'>980 W</p>
    <p class='paragrafi1'>650.00 m3</p>
    <p class='paragrafi1'>1.20 l/orë</p>
    <p class='paragrafi1'>38.00 dB</p>
    <p class='paragrafi1'>NGA -22℃ DERI NË 43℃</p>
    <p class='paragrafi1'>10.00 kg</p>
    <p class='paragrafi1'>33.00 kg</p>
    <p class='paragrafi1'>deri në 40 m2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'>filtër - vitamin C</p>
    <p class='paragrafi1'>810 X 585 X 280 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>


</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/j12.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> J-18000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5300/5600 W<br></br>
                        Grofje / Ftofje: NGA -25 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:39.990  <br></br>Cmimi me Montim:41.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> J-18000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <div className='paragrafi1'>
  <p className='paragrafi1'>A++ (7/4.19)</p>
  <p className='paragrafi1'>5900.00 W</p>
  <p className='paragrafi1'>6000.00 W</p>
  <p className='paragrafi1'>5300/5600 W</p>
  <p className='paragrafi1'>1580 W</p>
  <p className='paragrafi1'>1550 W</p>
  <p className='paragrafi1'>850.00 m3</p>
  <p className='paragrafi1'>2.50 l/orë</p>
  <p className='paragrafi1'>57.00 dB</p>
  <p className='paragrafi1'>NGA -25 ℃ DERI NË 49 ℃</p>
  <p className='paragrafi1'>11.50 kg</p>
  <p className='paragrafi1'>28.00 kg</p>
  <p className='paragrafi1'>deri në 55 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>785 X 555 X 300 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>

</div>


</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>





<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/q12.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> Q-18000BTU </div>
                    <div className="card__subtitle">Klasa: A+++ <br></br>
                        Ryma: 5280/5600 W<br></br>
                        Grofje / Ftofje: NGA-35 ℃ DERI NË 53 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:41.990  <br></br>Cmimi me Montim:48.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> Q-18000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A+++ (8.5/4.6)</p>
  <p className='paragrafi1'>5900.00 W</p>
  <p className='paragrafi1'>6100.00 W</p>
  <p className='paragrafi1'>5280/5600 W</p>
  <p className='paragrafi1'>1430 W</p>
  <p className='paragrafi1'>1330 W</p>
  <p className='paragrafi1'>1000.00 m3</p>
  <p className='paragrafi1'>2.50 l/orë</p>
  <p className='paragrafi1'>56.00 dB</p>
  <p className='paragrafi1'>NGA-35 ℃ DERI NË 53 ℃</p>
  <p className='paragrafi1'>13.50 kg</p>
  <p className='paragrafi1'>28.50 kg</p>
  <p className='paragrafi1'>deri në 55 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>785 X 555 X 300 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>



<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/AENI1.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-18CH50AENI R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5275/5568 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:41.990  <br></br>Cmimi me Montim:44.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-18CH50AENI R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7.0/4.0)</p>
    <p className='paragrafi1'>6272.00 W</p>
    <p className='paragrafi1'>7004.00 W</p>
    <p className='paragrafi1'>5275/5568 W</p>
    <p className='paragrafi1'>1550 W</p>
    <p className='paragrafi1'>1630 W</p>
    <p className='paragrafi1'>800.00 m3</p>
    <p className='paragrafi1'>1.80 l/h</p>
    <p className='paragrafi1'>41.00 dB</p>
    <p className='paragrafi1'>NGA -20℃  DERI NË 50℃</p>
    <p className='paragrafi1'>11.20 kg</p>
    <p className='paragrafi1'>33.50 kg</p>
    <p className='paragrafi1'>deri në 55 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>805 X 554 X 330 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>

</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/AERI1.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-18CH50AERI+ R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5275/5568 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:45.990  <br></br>Cmimi me Montim:49.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-18CH50AERI+ R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (7/4)</p>
    <p className='paragrafi1'>5900.00 W</p>
    <p className='paragrafi1'>5850.00 W</p>
    <p className='paragrafi1'>5275/5568 W</p>
    <p className='paragrafi1'>1550 W</p>
    <p className='paragrafi1'>1500 W</p>
    <p className='paragrafi1'>730.00 m3</p>
    <p className='paragrafi1'>1.80 l/h</p>
    <p className='paragrafi1'>43.00 dB</p>
    <p className='paragrafi1'>NGA -20℃  DERI NË 50℃</p>
    <p className='paragrafi1'>10.90 kg</p>
    <p className='paragrafi1'>33.50 kg</p>
    <p className='paragrafi1'>deri në 55 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>805 X 554 X 330 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>



<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/kb50.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img> KB50XS1EG</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5000/5600 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃ DERI NË 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:45.990  <br></br>Cmimi me Montim:48.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri1'></img> KB50XS1EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A++ (6.1/4.0)"</p>
    <p class='paragrafi1'>6000.00 W</p>
    <p class='paragrafi1'>6250.00 W</p>
    <p class='paragrafi1'>5000/5600 W</p>
    <p class='paragrafi1'>1540 W</p>
    <p class='paragrafi1'>1550 W</p>
    <p class='paragrafi1'>880.00 m3</p>
    <p class='paragrafi1'>2.00 l/orë</p>
    <p class='paragrafi1'>44.00 dB</p>
    <p class='paragrafi1'>NGA -20℃ DERI NË 43℃</p>
    <p class='paragrafi1'>10.00 kg</p>
    <p class='paragrafi1'>34.00 kg</p>
    <p class='paragrafi1'>deri në 55 m2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'>Filtri për pluhur</p>
    <p class='paragrafi1'>810 X 585 X 280 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>

</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>



<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aehi1.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-18CH50AEHI+ R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5275/5569 W<br></br>
                        Grofje / Ftofje: NGA -25 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:47.990  <br></br>Cmimi me Montim:52.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-18CH50AEHI+ R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (6.30/4.10)</p>
    <p className='paragrafi1'>6007.00 W</p>
    <p className='paragrafi1'>6008.00 W</p>
    <p className='paragrafi1'>5275/5569 W</p>
    <p className='paragrafi1'>1600 W</p>
    <p className='paragrafi1'>1680 W</p>
    <p className='paragrafi1'>750.00 m3</p>
    <p className='paragrafi1'>1.80 l/h</p>
    <p className='paragrafi1'>41.50 dB</p>
    <p className='paragrafi1'>NGA -25℃  DERI NË 50℃</p>
    <p className='paragrafi1'>11.30 kg</p>
    <p className='paragrafi1'>33.50 kg</p>
    <p className='paragrafi1'>deri në 55 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>805 X 554 X 330 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>

</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/f122.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> F-24000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 7300/7300 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:49.990  <br></br>Cmimi me Montim:52.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> F-24000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A++ (6.53/4.09)</p>
  <p className='paragrafi1'>7400.00 W</p>
  <p className='paragrafi1'>8000.00 W</p>
  <p className='paragrafi1'>7300/7300 W</p>
  <p className='paragrafi1'>2200 W</p>
  <p className='paragrafi1'>2200 W</p>
  <p className='paragrafi1'>1300.00 m3</p>
  <p className='paragrafi1'>3.10 l/orë</p>
  <p className='paragrafi1'>63.00 dB</p>
  <p className='paragrafi1'>NGA -15 ℃ DERI NË 49 ℃</p>
  <p className='paragrafi1'>12.50 kg</p>
  <p className='paragrafi1'>39.00 kg</p>
  <p className='paragrafi1'>deri në 75 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>900 X 700 X 350 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>



<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/KA50.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img> KA50BS0EG</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 5000/5400 W<br></br>
                        Grofje / Ftofje: NGA-22 ℃ DERI NË 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:49.990  <br></br>Cmimi me Montim:50.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri1'></img> KA50BS0EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A++ (7.8/4.6)"</p>
    <p class='paragrafi1'>6300.00 W</p>
    <p class='paragrafi1'>6200.00 W</p>
    <p class='paragrafi1'>5000/5400 W</p>
    <p class='paragrafi1'>1295 W</p>
    <p class='paragrafi1'>1380 W</p>
    <p class='paragrafi1'>950.00 m3</p>
    <p class='paragrafi1'>2.00 l/orë</p>
    <p class='paragrafi1'>44.00 dB</p>
    <p class='paragrafi1'>NGA-22℃ DERI NË 43℃</p>
    <p class='paragrafi1'>10.30 kg</p>
    <p class='paragrafi1'>39.00 kg</p>
    <p class='paragrafi1'>deri në 60 m2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'>Filtrues për pluhur</p>
    <p class='paragrafi1'>860 X 667 X 310 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>


</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>
<section  id="card1" className={`card1 ${!activeSection || activeSection === 'Favorit' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/j12.jpeg' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/fav.png' className='emri'></img> J-24000BTU </div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 7200/7200 W<br></br>
                        Grofje / Ftofje: NGA -25 ℃ DERI NË 49 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:54.990  <br></br>Cmimi me Montim:56.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/fav.png' className='emri'></img> J-24000BTU</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
  <p className='paragrafi1'>A++ (6.53/4.09)</p>
  <p className='paragrafi1'>7400.00 W</p>
  <p className='paragrafi1'>8000.00 W</p>
  <p className='paragrafi1'>7200/7200 W</p>
  <p className='paragrafi1'>2200 W</p>
  <p className='paragrafi1'>2200 W</p>
  <p className='paragrafi1'>1300.00 m3</p>
  <p className='paragrafi1'>3.10 l/orë</p>
  <p className='paragrafi1'>63.00 dB</p>
  <p className='paragrafi1'>NGA -25 ℃ DERI NË 49 ℃</p>
  <p className='paragrafi1'>14.00 kg</p>
  <p className='paragrafi1'>36.00 kg</p>
  <p className='paragrafi1'>deri në 75 m2</p>
  <p className='paragrafi1'>R32</p>
  <p className='paragrafi1'>Filtrat e pluhurit</p>
  <p className='paragrafi1'>900 X 700 X 350 mm</p>
  <p className='paragrafi1'>5 vjetë</p>
</div>



</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeqi.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-24CH70AEQIS R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 7034/7330 W<br></br>
                        Grofje / Ftofje: NGA -15 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:54.990  <br></br>Cmimi me Montim:59.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-24CH70AEQIS R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (6.1/4.0)</p>
    <p className='paragrafi1'>7913.00 W</p>
    <p className='paragrafi1'>7910.00 W</p>
    <p className='paragrafi1'>7034/7330 W</p>
    <p className='paragrafi1'>2600 W</p>
    <p className='paragrafi1'>2400 W</p>
    <p className='paragrafi1'>980.00 m3</p>
    <p className='paragrafi1'>2.40 l/h</p>
    <p className='paragrafi1'>45.00 dB</p>
    <p className='paragrafi1'>NGA -15℃  DERI NË 50℃</p>
    <p className='paragrafi1'>12.30 kg</p>
    <p className='paragrafi1'>42.90 kg</p>
    <p className='paragrafi1'>deri në 75 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>890 X 673 X 342 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>

</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>


<section id="card2" className={`card1 ${!activeSection || activeSection === 'Hisense' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/kb70.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/4.png' className='emri1'></img>  KB70BT1EG</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 6500/7100 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃ DERI NË 43 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:60.990  <br></br>Cmimi me Montim:63.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/4.png' className='emri'></img> KB70BT1EG</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p class='paragrafi1'>"A++ (6.2/4.0)"</p>
    <p class='paragrafi1'>7200.00 W</p>
    <p class='paragrafi1'>7300.00 W</p>
    <p class='paragrafi1'>6500/7100 W</p>
    <p class='paragrafi1'>2060 W</p>
    <p class='paragrafi1'>2150 W</p>
    <p class='paragrafi1'>1100.00 m3</p>
    <p class='paragrafi1'>2.20 l/orë</p>
    <p class='paragrafi1'>45.00 dB</p>
    <p class='paragrafi1'>NGA -20℃ DERI NË 43℃</p>
    <p class='paragrafi1'>11.00 kg</p>
    <p class='paragrafi1'>42.00 kg</p>
    <p class='paragrafi1'>deri në 70 m2</p>
    <p class='paragrafi1'>R32</p>
    <p class='paragrafi1'>filtër - vitamin C</p>
    <p class='paragrafi1'>860 X 667 X 310 mm</p>
    <p class='paragrafi1'>3 vjet</p>
</div>



</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>

<section id="card2" className={`card1 ${!activeSection || activeSection === 'Vivax' ? 'active' : ''}`}>
<div className="card__wrapper">
                        <div className="card__back">
                        </div>
                        <div className="card__menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29 14" height="14" fill="none">
                                <path fill="#000" d="m16.5714 9.16667h10.3572c.5493 0 1.0762.22827 1.4647.6346s.6067.95743.6067 1.53203c0 .5747-.2182 1.1258-.6067 1.5321s-.9154.6346-1.4647.6346h-10.3572c-.5493 0-1.0762-.2283-1.4647-.6346s-.6067-.9574-.6067-1.5321c0-.5746.2182-1.1257.6067-1.53203s.9154-.6346 1.4647-.6346zm-14.49997-8.66667h24.85717c.5493 0 1.0762.228273 1.4647.6346.3885.40633.6067.95743.6067 1.53207 0 .57463-.2182 1.12573-.6067 1.53206s-.9154.6346-1.4647.6346h-24.85717c-.54938 0-1.076254-.22827-1.464722-.6346s-.606708-.95743-.606708-1.53206c0-.57464.21824-1.12574.606708-1.53207.388468-.406327.915342-.6346 1.464722-.6346z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="card__img">
                        <img src='images/aeri1.png' className='reklama1'></img>
                    </div>
                    <div className="card__title"><img src='images/11.png' className='emri'></img> ACP-24CH70AERI+ R32</div>
                    <div className="card__subtitle">Klasa: A++ <br></br>
                        Ryma: 7034/7327 W<br></br>
                        Grofje / Ftofje: NGA -20 ℃  DERI NË 50 ℃ <hr className='vija'></hr></div>
                    <div className="card__wrapper">

                        <div className="card__price">Cmimi:61.990  <br></br>Cmimi me Montim:63.000 </div>
                        <div className="card__counter">
                                <button className='button2' >
                                    <div class="svg-wrapper-1">
                                        <div class="svg-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                        </svg>
                                        </div>
                                    </div>
                                    <span>Porosit</span>
                                    </button>
                        </div>
                    </div>
    <div class="card__content1">
    <div class="right">
    <h2><img src='images/11.png' className='emri'></img> ACP-24CH70AERI+ R32</h2>
    <div class="custom-wrapper">
  <div class="custom-left">
  <p className='paragrafi'>KLASA ENERGJETIKE (SEER/SCOP):</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGRICËS:</p>
    <p className='paragrafi'>KAPACITETI MAKSIMAL I NGROHJES:</p>
    <p className='paragrafi'>FORSAT E MESATARE:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGRICËS:</p>
    <p className='paragrafi'>FUQIA HYRËSE E NGROHJES:</p>
    <p className='paragrafi'>SHPEJTËSIA E AJRIT:</p>
    <p className='paragrafi'>KAPACITETI I LAGËSJËS:</p>
    <p className='paragrafi'>NIVELI I ZËRIT:</p>
    <p className='paragrafi'>TEMPERATURA E VEPRIMIT:</p>
    <p className='paragrafi'>MASA E NJËSISË SË BRENDSHME:</p>
    <p className='paragrafi'>MASA E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>HAPËSIRA E REKOMANDUAR:</p>
    <p className='paragrafi'>FREONI:</p>
    <p className='paragrafi'>FILTRO:</p>
    <p className='paragrafi'>DIMENSIONET E NJËSISË SË JASHTME:</p>
    <p className='paragrafi'>GARANCIA:</p>
  </div>
  <div class="custom-right">
    <p className='paragrafi1'>A++ (6.5/4)</p>
    <p className='paragrafi1'>8206.00 W</p>
    <p className='paragrafi1'>8206.00 W</p>
    <p className='paragrafi1'>7034/7327 W</p>
    <p className='paragrafi1'>2510 W</p>
    <p className='paragrafi1'>2130 W</p>
    <p className='paragrafi1'>1020.00 m3</p>
    <p className='paragrafi1'>2.00 l/h</p>
    <p className='paragrafi1'>47.00 dB</p>
    <p className='paragrafi1'>NGA -20℃  DERI NË 50℃</p>
    <p className='paragrafi1'>13.70 kg</p>
    <p className='paragrafi1'>43.90 kg</p>
    <p className='paragrafi1'>deri në 75 m2</p>
    <p className='paragrafi1'>R32</p>
    <p className='paragrafi1'>Filtrues për pluhur</p>
    <p className='paragrafi1'>890 X 673 X 342 mm</p>
    <p className='paragrafi1'>5 vjet</p>
</div>
</div>
    <div class="porosia">
  <div class="porosit">
    Porosit Tani:
  </div>
  <div class="thejr">
  <button className='button2' onClick={Phone}>
     <div class="svg-wrapper-1">
                 <div class="svg-wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
             <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                       </svg>
                 </div>
            </div>        
            <span>Porosit</span>    
      </button>
  </div>
</div>
  </div>
</div>
</section>
</div>
    
<footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
   </>


  );
}

export default Card;
