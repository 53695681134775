import "./servis.css";
import VideoPlayer from './VideoPlayer';
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import { Helmet } from "react-helmet";


function servis() {
    return(
        <>
                <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
          <div class="contineri">
          <Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/Servisimim" />
            </Helmet>
    <div class="cont">
      <h1>Çfarë mund të përdorni për të pastruar kondicionerin?</h1>
      <p>Shërbimi i ajrit të kondicionuar është një procedurë komplekse për të cilën është e 
            nevojshme të punësoni një teknik profesionist shërbimi, veçanërisht kur pajisja juaj 
            është nën garancinë e fabrikës (bazë ose të zgjatur). Megjithatë, ekziston një 'nivel i 
            thjeshtë pastrimi' që mund ta bëni vetë, në mënyrë periodike. Frekuenca e pastrimit të 
            thjeshtë varet nga sa orë pune punon kondicioneri juaj, kështu që për shembull nëse e 
            përdorni kondicionerin çdo ditë, atëherë filtri i ajrit të kondicionerit duhet të pastrohet 
            më shpesh. Me termin më shpesh, mendojmë se do të ishte e 
            këshillueshme të pastroni filtrin dhe avulluesin e kondicionerit tuaj një herë në muaj.</p>
    </div>
    <div class="imag">
      <img src="images/servisimage5.jpg" className="serv5" alt="Image 1"></img>
    </div>
  </div>


  <div class="contineri1">
    <div class="cont">
      <h1>Çfarë nevojitet për të pastruar kondicionerin?</h1>
      <p>Pastrues i kondicionerëve - agjent shkumës i biodegradueshëm, 
        pa acid për pastrimin e filtrave të ajrit, avulluesve dhe kondensatorëve të ftohur me ajër
        Dezinfektues i kondicionerit - i destinuar për kondicionerët dhe pajisjet e filtrimit të ajrit. Ai vepron në një gamë të
        gjerë bakteresh dhe virusesh, agjenti shkaktar i gripit, dhe mund të parandalojë zhvillimin e mykut dhe aromave të pakëndshme.
        Spray për dezinfektimin e klimës - përveç dezinfektimit, vepron edhe si deodorant dhe freskues ajri</p>
    </div>
    <div class="imag">
    <img src="images/servisimage1.jpg" className="serv4" alt="Image 1"></img>
      <img src="images/servisimage2.jpg"className="serv4"alt="Image 2"></img>
      <img src="images/servisimage3.jpg" className="serv4" alt="Image 3"></img>
      <img src="images/servisimage4.jpg"  className="serv4"alt="Image 3"></img>    </div>
  </div>
  <div class="contineri1">
    <div class="cont">
    <BackToTopButton />
      <h1>Si ta pastroj kondicionerin?</h1>
      <p>Edhe një herë, duhet të përmendim se shërbimi vjetor i kondicionerit 
            është padyshim një punë për një teknik të autorizuar servisi, por kjo 
            nuk do të thotë që ju nuk mund të pastroni periodikisht
             filtrin e ajrit dhe avulluesin e kondicionerit, në mënyrë që 
             kondicioneri juaj të jetë gjithmonë i përsosur. të pastër dhe të dezinfektuar.
            Hapat për pastrim të thjeshtë të kondicionerit:
            Hiqni filtrin e ajrit
            Lajeni tërësisht me ujë të ngrohtë dhe një agjent pastrues
            Pasi filtri të jetë tharë, spërkateni me dezinfektues ose spërkatës dezinfektues
            Vendoseni sërish filtrin e ajrit në pajisje
            Përdorni një fshesë me korrent për të thithur të gjithë pluhurin dhe papastërtitë nga avulluesi
            Lani avulluesin me detergjent
            Lëreni detergjentin të kullojë dhe të thahet në avullues (mund të ndizni kondicionerin në këtë hap për të shpejtuar procesin e tharjes)
            Spërkateni avulluesin me dezinfektues ose llak. Për të gjitha hapat e tjerë, kontaktoni një riparues të autorizuar të kondicionerëve</p>
    </div>
    <div class="imag">
    <VideoPlayer />
        </div>
  </div>
    
  <footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
  

</>
    )
}
export default  servis;
