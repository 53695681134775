import "./istallacija.css";
import VideoPlay from "./videoplay";
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly
import { Helmet } from "react-helmet";

function servis() {
    return(
        <>
                <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
<Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/istalacija" />
            </Helmet>
          <div class="contineriii">
    <div class="conta">
      <h1 className="hh">Cfar duhet te dimë per istalimin e njesisë te mbrendshme dhe te jashtme.</h1>
      <p className="pp">Instalimi i kondicionerit konsiston në instalimin e një njësie
         të brendshme dhe të jashtme, çdo kondicioner mono i ndarë duhet të ketë instalime që lidhin njësitë e
          brendshme dhe të jashtme. Këto instalime përbëhen nga dy tuba të izoluar prej bakri, një kabllo ndërlidhëse 
          për komunikimin ndërmjet njësisë së brendshme dhe të jashtme dhe një zorrë për kullimin e kondensatës nga n
          jësia e brendshme, përveç rasteve kur kondensata nga njësia e brendshme derdhet në një kullues aty pranë, p.sh. një njësi sanitare .
Për më tepër, është e nevojshme të vendosni kabllon për furnizimin me energji të kondicionerit dhe tubin për kullimin e kondensatës
 së ujit nga njësia e jashtme.[23:34, 14/08/2023] Keti kroaci: Instalimet mund të vendosen mbi suva në kanalet PVC dhe nën suva, me 
ç'rast është e nevojshme të bëhen të çara në mur në të cilinvendosen instalimet. Pas vendosjes së instalimeve në slota, është e nevojshme 
të punësoni një suvatues dhe suvatues për riparimin e mureve.
</p>
<VideoPlay />
    </div>
    <div class="imagess">
        <h1 className="hh">Izolime te mbrendshme</h1>
      <img src="images/mbreshme1.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme2.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme3.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme4.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme5.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme6.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme7.jpg" className="ser5" alt="Image 1"></img>
      <img src="images/mbreshme8.jpg" className="ser5" alt="Image 1"></img>
      <h1>Izolime te jashtme</h1>
      <img src="images/jashtme1.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme2.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme3.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme4.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme6.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme8.jpg" className="ser4" alt="Image 1"></img>
      <img src="images/jashtme9.jpg" className="ser4" alt="Image 1"></img>

      <BackToTopButton />
    </div>
  </div>
    
  <footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>

</>
    )
}
export default  servis;
