import './Contact.css';
import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MyModal from './MyModal';
import { Helmet } from 'react-helmet';



const Contact = () =>  {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [message, setMessage] = useState("");
  const form = useRef();
 
  const sendEmail = (e) => {
    e.preventDefault(); 
 
    emailjs.sendForm(
            'service_dqmi8jl', 
            'template_r1fdhsb', 
            form.current, 
            '7kKcRTeCZSr4JI5pp'
    )
    .then((result) => {
      setMessage("Your email has been successfully sent");
      handleOpen();
    }, (error) => {
      setMessage("Something went wrong! Please try again");
      handleOpen();
    });
  };
  
    return(
        <>
      <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
</style>
        <head>    
          <link rel="preconnect" href="https://fonts.googleapis.com"/>
          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap" rel="stylesheet"/>
        </head>
<div className="contact-container">
<Helmet>
                <meta charSet="utf-8" />
                <title>Frigoservis</title>
                <meta name="description" content='Ne bëjm Montimin Shitjen dhe Servisime per klima'></meta>
                <link rel="canonical" href="/contact" />
            </Helmet>
  <form ref={form} onSubmit={sendEmail}>
    <h2>Kontakt</h2>
    <div className="form-control">
      <label for="name">Name:</label>
      <input type="text" id="name" name="user_name" required/>
    </div>
    <div className="form-control">
      <label for="email">Email:</label>
      <input type="email" id="email" name="user_email" required/>
    </div>
    <div className="form-control">
      <label for="number">Number:</label>
      <input type="number" id="number" name="user_number" required/>
    </div>
    <div className="form-control">
      <label for="message">Message:</label>
      <textarea id="message" name="message" required></textarea>
    </div>
    <button type="submit">Send Message</button>
  </form>
  <MyModal message={message} open={open} handleClose={handleClose}/>
  <img src="images/neworiginal4.png" alt="Contact Image" />
</div>
    
<footer class="footer">
    <div class="l-footer">
    <h1><img src="images/neworiginal4.png" alt=""/></h1>
    </div>
<ul class="r-footer">
      <li className='pldh'>
        <h2>
      Social</h2>
      <ul class="box">
      <li><a href="https://www.facebook.com/profile.php?id=61558486479890">Facebook</a></li>
      <li><a href="https://www.instagram.com/frigo.servis1/">Instagram</a></li>
      <li><a href="#">Tik tok</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h2>
      Legal</h2>
      <ul class="box">
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Contract</a></li>
      </ul>
      </li>
      <li className='pldh'>
        <h1>kontakt </h1> 
      <ul class="box">
      <li>North Macedonia, Tetovo, Kamenjane</li>
      <li>Tel: 072 305 210 </li>
      <li>Tel: 070 567 923</li>
      <li>frigoservis00@gmail.com</li>

      </ul>
      </li>
</ul>
      <div class="b-footer">
      <p>
      All rights reserved by ©FrigoServis 2023 </p>
</div>
</footer>
  </>
    )
}
export default Contact;